import { useEffect } from 'react';

import { isDevEnvironment, isLocalEnvironment } from '@/utils/helpers.js';

const useWidget = () => {
    const isWidgetUsed = isLocalEnvironment() || isDevEnvironment();

    useEffect(() => {
        if (!isWidgetUsed) return;

        const existingScript = document.querySelector('script.crisp-widget-script');
        if (existingScript) return;

        window.$crisp = [];
        window.CRISP_WEBSITE_ID = import.meta.env.VITE_CRISP_WEBSITE_ID;
        const scriptElement = document.createElement('script');
        scriptElement.src = 'https://client.crisp.chat/l.js';
        scriptElement.async = 1;
        scriptElement.classList.add('crisp-widget-script');
        document.head.appendChild(scriptElement);
    }, [isWidgetUsed]);
};

export default useWidget;
